import { Card, CardHeader, Typography } from "@mui/material";
import React, { useContext } from "react";
import { IMessage } from "@twin-shared";
import { ThemeContext } from "../../providers";
import { hexToRgba } from "@twin-shared";

interface ChatBubbleProps {
  message: IMessage;
  className?: string;
  key: string;
  sx?: any;
  IconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  setRef?: (ref: any) => void;
}

export const ChatBubble = ({
  message,
  key,
  sx,
  children,
  setRef,
}: ChatBubbleProps) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Card
      ref={(ref) => setRef && setRef(ref)}
      key={key}
      className={
        message.sender === "user" ? "wrapper-with-end" : "wrapper-with-start"
      }
      style={{
        backgroundColor:
          message.sender === "mimi"
            ? hexToRgba(theme.colors.background, theme.isDark ? 0.3 : 0.6)
            : hexToRgba(theme.colors.primary, theme.isDark ? 0.8 : 0.6),
      }}
      sx={{ ...sx }}
      data-testid="chat-bubble-card"
    >
      <CardHeader
        component="div"
        title={
          <Typography
            component="div"
            sx={{
              whiteSpace: "pre-wrap",
              fontSize: "1rem",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
              "@media (max-width: 400px)": {
                fontSize: "0.8rem",
              },
              "@media (max-width: 300px)": {
                fontSize: "0.7rem",
              },
              "@media (max-width: 200px)": {
                fontSize: "0.6rem",
              },
            }}
          >
            {children}
          </Typography>
        }
      />
    </Card>
  );
};
