import React, { useEffect } from "react";
import { useThemeContext } from "../../providers";

interface ThemeSwitchProps {
  args: any;
}

export const ThemeSwitch = (props: ThemeSwitchProps) => {
  const { args } = props;
  const { theme_name } = args || {};
  const { theme, toggleTheme } = useThemeContext();

  useEffect(() => {
    if (theme_name && theme.name !== theme_name) {
      toggleTheme(theme_name);
    }
  }, [theme_name]);

  return <></>;
};
