 import { useEffect, useRef, useState, } from "react";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { setActiveVideoId } from "../../features/message/messageSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export const VideoPlayback = ({ component }: { component: any }) => {
  const [nSeconds, setNSeconds] = useState(component.rewind_skip_seconds); // Default value if the backend is not yet loaded
  const [playing, setPlaying] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [played, setPlayed] = useState(0); // Percentage of the video that has been played
  const [duration, setDuration] = useState(0); // Total duration of the video
  const [currentTime, setCurrentTime] = useState(0); // Current playback time
  const [errorMessage, setErrorMessage] = useState(''); 
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const dispatch = useDispatch(); 
  const { activeVideoId } = useSelector((state: RootState) => state.message);

 
  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (activeVideoId === component.id) {
      setPlaying(true);
    } else {
      setPlaying(false);
    } 
  }, [activeVideoId,component]);

 

  let url = component.video_url;
  url = url.replace(
    'https://www.dropbox.com',
    'https://dl.dropboxusercontent.com',
  );
  url = url.replace(
    'dl=0',
    'raw=1',
  );
  url = url.replace(
    'dl=1',
    'raw=1',
  );
  
  return (
    <div
      ref={containerRef}
      className={`custom-player ${fullscreen ? "fullscreen" : ""}`}>
      {url &&
        <div className="react-player-wrapper">
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing} 
          className={(url.includes('youtu') || url.includes('youtube')) ? 'react-player-yt' : 'react-player'}
          style={{ filter: "invert(0)" }}
          controls={ true}
          width="100%"
          height="100%" 
          onPlay={() => {
            dispatch(setActiveVideoId(component.id));
           }}
          onPause={() => { 
            dispatch(setActiveVideoId(undefined)); 
          }}
          onError={(err) => {
            setErrorMessage(err)
          }}
          /> 
        </div>
      }  
    </div>
  );
};
