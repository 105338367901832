import { useContext } from "react";
import { ThemeContext } from "../../providers";

const styleClass = "even:bg-white odd:bg-gray-300";
// const styleClass = '';
export const List = ({
  component,
  render,
}: {
  component: any;
  render: (any) => any;
}) => {
  const children = component.children;
  const swapcolor = component?.swapcolor;
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ ...component.ui_args?.web }}>
      {children.map((child, index) => {
        let background = { backgroundColor: "transparent" };
        if (swapcolor)
          background =
            index % 2 == 0
              ? { backgroundColor: theme.isDark ? "#444c48" : "#d1d5db" }
              : { backgroundColor: theme.colors.background };

        return (
          <div key={index} style={{ ...background }}>
            {render(child)}
          </div>
        );
      })}
    </div>
  );
};
