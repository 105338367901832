import React, { useCallback, useContext, useEffect, useState } from "react";
import { IMessage } from "@twin-shared";
import { AutoComplete } from "antd";
import { ThemeContext } from "../../providers";

interface AutoCompleteInput {
  data: any;
  component: any;
  message: IMessage;
  args: any;
  sendUiComponentQuery: (
    messageId: any,
    componentId: any,
    action: any,
    query: any,
    category: any
  ) => Promise<any>;
  onChange: (component: any, value: any) => Promise<any>;
}

export const AutoCompleteInput = (props: AutoCompleteInput) => {
  const { theme } = useContext(ThemeContext);
  const {
    data,
    component,
    message,
    onChange,
    args,
    sendUiComponentQuery,
    ...rest
  } = props;

  const [autoCompleteData, setAutoCompleteData] = useState<
    Array<{ lable: string; value: any }>
  >(() =>
    component.options.map((option: any, index: number) => ({
      value: option.value || index,
      label: option.label ? option.label : option?.value ? option?.value : "",
    }))
  );
  const [initialValue] = useState<any>({ id: data[component.id]});

  const [inputText, setInputText] = useState(data[component.id] || "");

  useEffect(() => {
    setInputText(data[component.id])
  }, [data[component.id]])

  useEffect(() => {
    if (
      data[component.id] !== null &&
      component.options.findIndex(
        (a: { value: any }) => a.value === data[component.id]
      ) === -1
    ) {
      sendUiComponentQuery(
        message.messageId,
        component.id,
        component.search_action,
        data[component.id],
        component.search_category
      ).then((data) => {
        setAutoCompleteData(
          data.map((option: any, index: number) => ({
            value: option.value || index,
            label: option.label
              ? option.label
              : option?.value
                ? option?.value
                : "",
          }))
        );
      });
    } else {
      setAutoCompleteData(
        component.options.map((option: any, index: number) => ({
          value: option.value || index,
          label: option.label
            ? option.label
            : option?.value
              ? option?.value
              : "",
        }))
      );
    }

    // setInitialValue({ id: data[component.id] });
  }, [data[component.id]]);

  let searchQueryTimeout: any = null;
  const onAutoComplete = useCallback(
    async (component: any, query: any) => {
      if (!component.search_action) {
        return [];
      }
      if (searchQueryTimeout) {
        clearTimeout(searchQueryTimeout);
      }
      searchQueryTimeout = setTimeout(async () => {
        const result = await sendUiComponentQuery(
          message.messageId,
          component.id,
          component.search_action,
          query,
          component.search_category
        );

        setAutoCompleteData(
          result.map((option: any, index: number) => ({
            value: option.value || index,
            label: option.label
              ? option.label
              : option?.value
                ? option?.value
                : "",
          }))
        );
        onChange(component, query);
      }, 300);
    },
    [message, onChange]
  );
  return (
    <AutoComplete
      placeholder={component.placeholder}
      value={inputText}
      options={autoCompleteData}
      virtual
      defaultValue={initialValue}
      onSearch={(query) => {
        setInputText(query || "");
        onAutoComplete(component, query);
      }}
      onSelect={(value) => {
        setInputText(value);
        onChange(component, value);
      }}
      //@ts-ignore
      onBlur={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
      className="h-9"
      popupClassName={theme.isDark ? "data-theme-bg" : ""}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        borderColor: theme.isDark ? theme.colors.border : null,
      }}
      data-testid="auto-complete"
    />
  );
};
