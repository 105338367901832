import { Button } from "antd";
import { ReactComponent as SendIcon } from "@twin-shared/lib/assets/send.svg";
import { ThemeContext } from "../../providers";
import { useContext } from "react";

export const SubmitButton = ({ component }: { component: any }) => {
    const {theme} = useContext(ThemeContext);
    const backgroundColor = component?.backgroundColor || theme.colors.secondary;
    const textColor = component?.textColor || theme.colors.textSecondary;
    return (
        <Button
            key={component.id}
            htmlType="submit"
            className="rounded-[5px] max-[28px] h-[27px]"
            style={{
                backgroundColor: backgroundColor,
                color: textColor,
            }}
            {...component.ui_args?.web}
        >
            {component.label ? component.label : <SendIcon className="send-icon" fill={textColor} width={'16px'} height={'16px'} />}
        </Button>
    );
}