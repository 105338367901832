import React, { useContext } from "react";
import { DatePicker } from "antd";
import { convertDateTime, formatDateTime } from "../../utility";
import { ThemeContext } from "../../providers";

interface DateInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const DateInput = (props: DateInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;

  return (
    <DatePicker
      picker={component.granularity === "day" ? "date" : component.granularity}
      placeholder={component.placeholder}
      value={convertDateTime(data[component.id], component)}
      onChange={(date) => onChange(component, formatDateTime(date, component))}
      {...args}
      {...rest}
      className="h-9"
      popupClassName={theme.isDark ? "dark-theme" : ""}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        border: 0
      }}
    />
  );
};
