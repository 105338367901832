import { useContext } from "react";
import { ThemeContext } from "../../providers";

interface SegmentProps {
  departure_time: string;
  departure_next_day: string;
  departure_airport: string;
  departure_city: string;
  duration: string;
  arrival_time: string;
  arrival_next_day: string;
  arrival_airport: string;
  arrival_city: string;
  carrier: string;
  flight_number: string;
}

const style1 = "text-[14px] text-black font-medium";
const style2 = "text-[10px] text-gray-500";

export const FlightSegment = ({ args }: { args: SegmentProps }) => {
  const { theme } = useContext(ThemeContext);

  if (!args) return <></>;

  return (
    <div className="font-calibri min-w-max">
      <div className={`flex gap-[15px] ${style1}`}>
        <div className="flex">
          <span style={{ color: theme.colors.textPrimary }}>
            {args.departure_time}
          </span>
          <span
            className={`text-[10px]`}
            style={{ color: theme.colors.textPrimary }}
          >
            {args.departure_next_day}
          </span>
        </div>
        <div style={{ color: theme.colors.textPrimary }}>{args.departure_airport}</div>
        <div style={{ color: theme.colors.textPrimary }}>{args.departure_city}</div>
      </div>

      <div className={`${style2} text-gray-500`}>{args.duration}</div>

      <div className={`flex gap-[15px] ${style1}`}>
        <div className="flex">
          <span style={{ color: theme.colors.textPrimary }}>{args.arrival_time}</span>
          <span className={`text-[10px]`} style={{ color: theme.colors.textPrimary }}>{args.arrival_next_day}</span>
        </div>
        <div style={{ color: theme.colors.textPrimary }}>{args.arrival_airport}</div>
        <div style={{ color: theme.colors.textPrimary }}>{args.arrival_city}</div>
      </div>

      <div className={`${style2} text-gray-500`}>
        {`${args.carrier} ${args.flight_number}`}
      </div>
    </div>
  );
};
