import React, { useContext, useRef } from "react";
import {  VictoryAxis,   VictoryChart, VictoryLabel, VictoryScatter, VictoryTheme } from "victory";
import "./styles.css";
import { ThemeContext } from "../../providers";
  

interface ChartScatterProps {
  allargs: any;
}

export const ChartScatter = (props: ChartScatterProps) => {

  const uiArgs = props?.allargs?.ui_args?.web;
  const {theme} = useContext(ThemeContext);

 return (
    <div className="chart-container"
    style={{
      paddingLeft: props?.allargs?.x_label ? 10 : 0,   
    }}
    >
     <p className="chart-title" >{`${props?.allargs?.title}`}</p>   
     {/* <p  >{JSON.stringify(props?.allargs)}</p>    */}
 
     <div style={{}}>
       <VictoryChart
         theme={VictoryTheme?.material}  
         domainPadding={uiArgs?.domainPadding ?? 0} // Padding around chart edges
         scale={uiArgs?.scale}
         domain={uiArgs?.domain}
       >  
        {/* X Axis Labels */}
       <VictoryAxis label={props?.allargs?.x_label}
          style={uiArgs?.xAxisStyle?? {
            axisLabel: { padding: props?.allargs?.x_label ? 35: 0 },  
            tickLabels: { fontSize: 10, padding: 5 },  
            grid: { stroke: "#cdcdcd",strokeOpacity: 0.3 },
           }} 
       />
        {/* Y Axis Labels */}

        <VictoryAxis dependentAxis label={props?.allargs?.y_label}
          style={ uiArgs?.yAxisStyle ??{
            axisLabel: { padding: props?.allargs?.y_label ? 35 : 0 },  
            tickLabels: { fontSize: 10, padding: 5 },  
            grid: {stroke:   "#cdcdcd",strokeOpacity: 0.3},
          }}
        />  
         {props?.allargs?.data && <VictoryScatter
           {...uiArgs}
           style={uiArgs?.style ? uiArgs?.style : {
             data: {
               fill: ({ datum }) => datum.fill ?? theme?.colors?.primary,
               opacity: ({ datum }) => datum.opacity,
             },
           }}
           padding={uiArgs?.padding}
           data={props?.allargs?.data}
           x="x"
           domainPadding={undefined}
           domain={undefined} 
           labelComponent={<VictoryLabel
            dy={uiArgs?.labelComponent?.dy}  
            dx={uiArgs?.labelComponent?.dx}     
          />}
           
         />}
      </VictoryChart>
     </div>
    </div>
  );
};
