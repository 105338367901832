import { useContext } from "react";
import { ThemeContext } from "../../providers";
import {FlightSegment} from "./FlightSegment";

interface FlightMultiSegmentProps {
    departure_date: string,
    price: string,
    layovers: [],
}

const style1 = "text-[12px] text-black truncate";

export const FlightMultiSegment = ({ args, children, render }: { args: FlightMultiSegmentProps, children: any, render: (any) => any }) => {
    const {theme} = useContext(ThemeContext);

    return (
        <div className="font-calibri min-w-max">
            <div className="flex justify-between items-center">
                <div className={`${style1}`} style={{ color: theme.colors.textPrimary }}>{`Depart ${args.departure_date}`}</div>
            </div>

            {
                children.map((child, index) => {
                    return (
                        <div key={index} style={{ color: theme.colors.textPrimary }} >
                            <FlightSegment args={child.args} />
                            {
                                args.layovers[index] &&
                                <div className={`${style1}`} style={{ color: theme.colors.textPrimary }}>{`${args.layovers[index]} layover`}</div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}