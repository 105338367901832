import React, { useContext } from "react";
import { ThemeContext } from "../../providers";

export const ExtraInfo = ({ info }: { info: any }) => {
  const { theme } = useContext(ThemeContext);

  if (!info) return <></>;
  const keys = Object.keys(info);

  return (
    <div
      className="grid grid-cols-2 p-[10px]"
      style={{ backgroundColor: theme.isDark ? "#444c48" : "white" }}
    >
      {keys.map((idxstr, index) => {
        return (
          <div key={`${idxstr}_${index}`}>
            <div className="font-bold max-w-[120px] truncate">{idxstr}</div>
            {info[idxstr] instanceof Array &&
              info[idxstr].map((feature, index) => {
                return (
                  <div
                    key={index}
                    className="text-[10px] truncate max-w-[80px]"
                  >
                    {`✓ ${feature}`}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
