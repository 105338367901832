import React, { useContext, useRef } from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from "victory";
import "./styles.css";
 import { ThemeContext } from "../../providers";

interface ChartBarProps {
  allargs: any;
}

export const ChartBar = (props: ChartBarProps) => {
  const uiArgs = props?.allargs?.ui_args?.web;
  const { theme } = useContext(ThemeContext);

  const customTheme = {
    ...VictoryTheme?.material,
    bar: {
      style: {
        data: {
          fill: theme.colors.primary 
        }
      }
    }
  }; 
  return (
    <div
      className="chart-container"
      style={{
        paddingLeft: props?.allargs?.x_label ? 10 : 0,
      }}> 
      <p className="chart-title" >{`${props?.allargs?.title}`}</p>   
      {/* <p>{`${JSON.stringify(props?.allargs)}`}</p>      */}
      <VictoryChart
        theme={customTheme}  
        domainPadding={uiArgs?.domainPadding ?? 20} // Padding around chart edges
        scale={uiArgs?.scale}
        domain={uiArgs?.domain}
      >
        {/* X Axis Labels */}
        <VictoryAxis label={props?.allargs?.x_label}
          style={uiArgs?.xAxisStyle ?? {
            axisLabel: { padding: props?.allargs?.x_label ? 35: 0 },  
            tickLabels: { fontSize: 10, padding: 5 }, 
            grid: { stroke: "#cdcdcd",strokeOpacity: 0.3 },
          }}
       />
        {/* Y Axis Labels */}

        <VictoryAxis dependentAxis label={props?.allargs?.y_label}
          style={uiArgs?.xAxisStyle ?? {
            axisLabel: { padding: props?.allargs?.y_label ? 35 : 0 },  
            tickLabels: { fontSize: 10, padding: 5 },  
            grid: { stroke: "#cdcdcd",strokeOpacity: 0.3 },
          }}
        />  
        <VictoryBar
          {...uiArgs}
           data={props?.allargs?.data}
           x="x"
           domainPadding={undefined}
           domain={undefined}
         />
      </VictoryChart>
    </div>
  );
};
