import { ReactComponent as FlightIcon } from "@twin-shared/lib/assets/flight.svg";
import { ThemeContext } from "../../providers";
import { useContext } from "react";

interface FlightTravelListSummaryProps {
  departure_airport: string;
  arrival_airport: string;
  departure_date: string;
  price: string;
  departure_time: string;
  arrival_time: string;
  next_day: string;
  number_of_stops: string;
}

const style1 = "text-[14px] text-black font-medium";
const style2 = "text-[12px] text-[#888888]";

export const FlightTravelListSummary = ({ component }: { component: any }) => {
  const { theme } = useContext(ThemeContext);

  const args: FlightTravelListSummaryProps = component.args;
  if (!args) return <></>;

  return (
    <div className="flex gap-[5px] items-center justify-between font-calibri min-w-max">
      <div className="flex gap-[5px] items-center">
        <FlightIcon width={"30px"} height={"30px"} />
        <div className="flex-grow">
          <div className={`flex gap-[20px] ${style1}`}>
            <div className={`flex items-center gap-[5px]`}>
              <span
                style={{ color: theme.colors.textPrimary }}
              >{`${args.departure_airport}`}</span>
              <span className="text-[#888888] text-[10px]"> {`▶`}</span>
              <span
                style={{ color: theme.colors.textPrimary }}
              >{`${args.arrival_airport}`}</span>
            </div>

            <span style={{ color: theme.colors.textPrimary }}>
              {args.departure_date}
            </span>
          </div>

          <div className={`${style2} flex`}>
            <span className="text-gray-500">{`${args.departure_time}`}</span>
            <span className="text-gray-500">{` - ${args.arrival_time}`}</span>
            <span className="text-[10px] text-gray-500">{args.next_day}</span>
          </div>
        </div>
      </div>

      <div className="text-right">
        <div
          className={`${style1}`}
          style={{ color: theme.colors.textPrimary }}
        >
          {args.price}
        </div>
        <div className={`${style2} text-gray-500`}>{args.number_of_stops}</div>
      </div>
    </div>
  );
};
