import React, { useContext, useEffect, useRef, useState } from "react";
import { VictoryPie } from "victory";
 import "./styles.css";
import { ThemeContext } from "../../providers";

interface ChartPieProps {
  allargs: any;
}

export const ChartPie = (props: ChartPieProps) => {
  const useArgs = props?.allargs?.ui_args?.web;
  const { theme } = useContext(ThemeContext);

  const [data, setData] = useState([]); 
  const getColorByIndex = (index: number) => {
    if (theme?.colorList?.length>0) {
      const normalizedIndex = index % theme?.colorList?.length; 
      return  theme.colorList[normalizedIndex] ; 
    } else {
      return theme.colors.primary;
    }
 };
  
  useEffect(() => {
    const pieData = props?.allargs?.data.map((element, index) => {
      // Create a shallow copy of the element to avoid mutating the original object
      const item = { ...element }; 
      // Assign a color if not present
      if (!item.color) {
        item.color = getColorByIndex(index);
       }
      return item;
    });
  
    setData(pieData);
  }, [props?.allargs]);

  return (
    <div
    className="chart-container"
    style={{
      paddingLeft: props?.allargs?.x_label ? 10 : 0,
    }}>
 
    <p className="chart-title" >{`${props?.allargs?.title}`}</p>   
      {/* <p>{`${JSON.stringify(props?.allargs)}`}</p>    */}
      <VictoryPie
        {...useArgs} 
        data={data}
        x="label" // Field for labels
        y="value" // Field for values 
        colorScale={data?.map((item) => item.color)} 
        padAngle={useArgs?.padAngle ? useArgs?.padAngle : 0}
        startAngle={useArgs?.startAngle ? useArgs?.startAngle : 0}
        endAngle={useArgs?.endAngle ? useArgs?.endAngle : 360} 
      />
    </div>
    
  );
};
