import { Button } from "antd";
import { useSocketContext } from "../../providers";
import { ThemeContext } from "../../providers/ThemeProvider";
import { useContext } from "react";

const defaultstyleClass = "!rounded-[5px]";

export const ActionButton = ({
  messageid,
  component,
  data,
}: {
  messageid: string;
  component: any;
  data: any;
}) => {
  const { sendUiComponentAction } = useSocketContext();
  const { theme } = useContext(ThemeContext);
  const backgroundColor = component?.backgroundColor || theme.colors.primary;
  const textColor = component?.textColor || theme.colors.textPrimary;

  return (
    <Button
      key={component.id}
      onClick={() =>
        sendUiComponentAction(messageid, component.id, component.action, data)
      }
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        border: 0
      }}
      className={`${defaultstyleClass} rounded-[5px]`}
    >
      {component.label}
    </Button>
  );
};
