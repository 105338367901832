import React, { useContext, useEffect } from "react";
import { TimePicker } from "antd";
import { convertDateTime, formatDateTime } from "../../utility";
import { getTimezoneOffset, removeTimezoneOffset } from "@twin-shared";
import { ThemeContext } from "../../providers";

interface TimeRangeInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TimeRangeInput = (props: TimeRangeInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;
  const [from = "", to = ""] = data[component.id] || [];
  let timeFormat = "" + component.format;
  if (component.use12hours) {
    timeFormat = component.format.replace("HH", "hh");
    if (!component.format?.toLowerCase().includes("a")) {
      timeFormat = component.format.toLowerCase() + " A";
    }
  }

  useEffect(() => {
    if (data[component.id] && data[component.id].length > 1) {
      let timeRange = [...data[component.id]];
      timeRange[0] = removeTimezoneOffset(timeRange[0]);
      timeRange[0] = timeRange[0] + " " + getTimezoneOffset();
      timeRange[1] = removeTimezoneOffset(timeRange[1]);
      timeRange[1] = timeRange[1] + " " + getTimezoneOffset();
      onChange(component, timeRange);
    }
  }, []);

  const manageValue = () => {
    if (data[component.id] && data[component.id].length > 1) {
      let timeRange = [...data[component.id]];
      if (timeRange[0] && timeRange[1]) {
        timeRange[0] = removeTimezoneOffset(timeRange[0]);
        timeRange[0] = timeRange[0] + " " + getTimezoneOffset();
        timeRange[1] = removeTimezoneOffset(timeRange[1]);
        timeRange[1] = timeRange[1] + " " + getTimezoneOffset();
        return convertDateTime(timeRange, component);
      }
    }
    return data[component.id];
  };

  const handleOnChange = (time: any) => {
    console.log("timetimetimetime" + time);
    let timeRange = [...formatDateTime(time, component)];
    if (timeRange && timeRange.length > 1) {
      timeRange[0] = removeTimezoneOffset(timeRange[0]);
      timeRange[0] = timeRange[0] + " " + getTimezoneOffset();

      timeRange[1] = removeTimezoneOffset(timeRange[1]);
      timeRange[1] = timeRange[1] + " " + getTimezoneOffset();
    }
    onChange(component, timeRange);
  };

  return (
    <TimePicker.RangePicker
      placeholder={component.placeholder}
      value={manageValue()}
      onChange={handleOnChange}
      format={timeFormat}
      {...args}
      {...rest}
      use12Hours={component.use12hours}
      className="h-9"
      popupClassName={theme.isDark ? "dark-theme dark-theme-for-edge" : ""}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        border: 0,
      }}
    />
  );
};
