import React, { useContext } from "react";
import { Radio } from "antd";
import { ThemeContext } from "../../providers";

interface RadioInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const RadioInput = (props: RadioInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;
  return (
    <Radio.Group
      value={data[component.id]}
      onChange={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
      style={{
        color: theme.colors.textPrimary,
        borderColor: theme.isDark ? theme.colors.border : null,
      }}
    >
      {component.options.map((option: any) => (
        <Radio
          key={option.value}
          value={option.value}
          style={{
            color: theme.isDark ? theme.colors.textSecondary: 'black',
          }}
        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
