import React, { useContext, useEffect, useRef } from "react";
import "./styles.css";
import { ThemeContext } from "../../providers";


interface HtmlViewProps {
   htmlContent: any;
}
  
export const HtmlView = (props: HtmlViewProps) => {
  const iframeRef = useRef(null);
  const [htmlData, setHtmlData] = React.useState<any>(props.htmlContent);
  const { theme } = useContext(ThemeContext);
  const isDarkTheme = theme.isDark; 

  const replaceAllColorsInHTMLString = (html:any, newColor:any) => {
    const colorRegex = /color:\s*[^;]+;/g;
    const bgColorRegex = /background-color:\s*[^;]+;/g;

    html = html.replace(colorRegex, `color: ${newColor};`);
    html = html.replace('<body>', `<body style="color: ${newColor}">`);
    html = html.replace(bgColorRegex, `background-color: ${newColor};`);
    return html;
  };

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      if (iframeDocument) {
         const style = `
          html, body { 
            overflow: hidden;
          }
        `;
        const styleElement = iframeDocument.createElement("style");
        styleElement.textContent = style;
        iframeDocument.head.appendChild(styleElement);

        // Adjust iframe height based on content
        iframe.style.height = iframeDocument.body.scrollHeight + "px";
      }
    }
  };
 
  useEffect(() => {
    if (isDarkTheme) {
      let html1 = replaceAllColorsInHTMLString(props.htmlContent, "#fff");
      setHtmlData(String(html1));
    } else {
      setHtmlData(props.htmlContent);
    }
  }, [isDarkTheme]); 
  
  
  return (
    <div>
      <iframe
        ref={iframeRef}
        srcDoc={htmlData}
        onLoad={handleIframeLoad}
        className="responsive-iframe float-left" 
        data-testid="html-view"
      />
    </div>
  );
};
