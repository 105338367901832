import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeName, ITwinTheme, MarineTheme, VeggiesTheme } from "@twin-shared";

interface ContextProps {
  theme: ITwinTheme;
  isDark: boolean;
  toggleTheme: (name?: ThemeName) => void;
}

export const ThemeContext = createContext<ContextProps>({
  theme: MarineTheme.Light,
  isDark: false,
  toggleTheme: (name?: ThemeName) => {},
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [activeThemeName, setActiveThemeName] = useState<ThemeName>(
    ThemeName.MARINE
  );
  const [theme, setTheme] = useState<ITwinTheme>(MarineTheme.Light);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const darkThemeMatchQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    darkThemeMatchQuery?.addListener(matchMediaListener);
    return () => darkThemeMatchQuery?.removeListener(matchMediaListener);
  }, []);

  const matchMediaListener = (e) => {
    setIsDark(e.matches);
    setTheme(e.matches ? MarineTheme.Dark : MarineTheme.Light);
  };

  const toggleTheme = (name?: ThemeName) => {
    if (name) {
      setActiveThemeName(name);
      if (name === ThemeName.MARINE) {
        setTheme(theme.isDark ? MarineTheme.Dark : MarineTheme.Light);
      } else {
        setTheme(theme.isDark ? VeggiesTheme.Dark : VeggiesTheme.Light);
      }
    } else {
      setActiveThemeName(
        activeThemeName === ThemeName.MARINE
          ? ThemeName.VEGGIES
          : ThemeName.MARINE
      );
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isDark }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("ThemeContext must be used inside the ThemeProvider");
  }

  return context;
};
