import React, { useContext, useRef } from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import "./styles.css";
  

interface ChartLineProps {
  allargs: any;
}

export const ChartLine = (props: ChartLineProps) => {

 const uiArgs = props?.allargs?.ui_args?.web;
 return (
    <div className="chart-container"
    style={{
      paddingLeft: props?.allargs?.x_label ? 10 : 0,   
    }}
    >
      <p className="chart-title" >{`${props?.allargs?.title}`}</p>   
  
       <VictoryChart
        theme={VictoryTheme?.material} // Apply theme for a polished look
        domainPadding={uiArgs?.domainPadding ?? 0} // Padding around chart edges
        scale={uiArgs?.scale}
        domain={uiArgs?.domain}
      >  
    
        {/* X Axis Labels */}
        <VictoryAxis label={props?.allargs?.x_label}
          style={uiArgs?.xAxisStyle ?? {
            axisLabel: { padding: props?.allargs?.x_label ? 35: 0 },  
            tickLabels: { fontSize: 10, padding: 5 }, 
            grid: { stroke: "#cdcdcd",strokeOpacity: 0.3 },
          }}
       />
        {/* Y Axis Labels */}

        <VictoryAxis dependentAxis label={props?.allargs?.y_label}
          style={uiArgs?.xAxisStyle ?? {
            axisLabel: { padding: props?.allargs?.y_label ? 35 : 0 },  
            tickLabels: { fontSize: 10, padding: 5 },  
            grid: { stroke: "#cdcdcd",strokeOpacity: 0.3 },
          }}
        />  
        <VictoryLine
          {...uiArgs}
          style={uiArgs?.style}
          padding={uiArgs?.padding}
          data={props?.allargs?.data}
          x="x"
          domainPadding={undefined} 
          domain={undefined}
          /> 
      </VictoryChart>
    </div>
  );
};
