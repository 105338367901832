import dayjs from "dayjs";
import { ComponentTypes } from "@twin-shared";

export const convertTimeValue = (timeValue: string, use12hours) => {
  if (timeValue) {
    if (use12hours) {
      if (
        timeValue.toLowerCase().includes("a") ||
        timeValue.toLowerCase().includes("p")
      ) {
        return dayjs(timeValue, ["hh:mm:ss A", "hh:mm A"]).format("hh:mm:ss A");
      }
      return dayjs(timeValue, [
        "HH:mm:ss",
        "HH:mm",
        "hh:mm:ss A",
        "hh:mm A",
      ]).format("hh:mm:ss A");
    } else {
      // Convert 12-hour format with AM/PM to 24-hour format
      return dayjs(timeValue, [
        "hh:mm:ss A",
        "hh:mm A",
        "HH:mm:ss",
        "HH:mm",
      ]).format("HH:mm:ss");
    }
  }
};

export const convertDateTime = (value: any, component: any) => {
  if (component.type === ComponentTypes.DateInput) {
    return value ? dayjs(value) : null;
  }

  if (component.type === ComponentTypes.DateRangeInput) {
    return Array.isArray(value)
      ? value.map((v: any) => (v ? dayjs(v) : null))
      : value;
  }

  if (component.type === ComponentTypes.TimeInput) {
    if (!value) return value;
    const seconds = dayjs(value, "HH:mm:ss");
    return seconds.isValid() ? seconds : null;
  }

  if (component.type === ComponentTypes.TimeRangeInput) {
    let timeFormat = "" + component.format;
    if (component.use12hours) {
      timeFormat = component.format.replace("HH", "hh");
      if (!component.format?.toLowerCase().includes("a")) {
        timeFormat = timeFormat.toLowerCase() + " A";
      }
    }

    return Array.isArray(value)
      ? value.map((v: any) => {
          if (!v) return null;
          return dayjs(convertTimeValue(v, component.use12hours), timeFormat);
        })
      : value;
  }

  return value;
};

export const formatDateTime = (value: any, component: any) => {
  let format;
  switch (component.granularity) {
    case "year":
      format = "YYYY";
      break;
    case "month":
      format = "YYYY-MM";
      break;
    case "day":
      format = "YYYY-MM-DD";
      break;
    default:
      format = "YYYY-MM-DD";
  }

  if (component.type === ComponentTypes.DateInput) {
    return value ? value.format(format) : null;
  }

  if (component.type === ComponentTypes.DateRangeInput) {
    return Array.isArray(value)
      ? value.map((v: any) => (v.isValid() ? v.format(format) : null))
      : [];
  }

  if (component.type === ComponentTypes.TimeInput) {
    return value ? value.format(component.format) : null;
  }

  if (component.type === ComponentTypes.TimeRangeInput) {
    return Array.isArray(value)
      ? value.map((v: any) => {
          const seconds = dayjs(v, "HH:mm:ss");
          return seconds.isValid() ? seconds.format(component.format) : null;
        })
      : [];
  }

  return value;
};