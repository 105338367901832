import React, { useContext } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { ThemeContext } from "../../providers";

interface SwitchInputProps {
  data: any;
  component: any;
  onChange: (component: any, value: any) => void;
  args?: any;
}

export const SwitchInput = (props: SwitchInputProps) => {
  const { data, component, onChange, args, ...restArgs } = props;
  const { theme } = useContext(ThemeContext);
  const bgColor = theme.isDark ? "#444444" : theme.colors.primary;

  return (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={data[component.id]}
      onChange={(value) => onChange(component, value)}
      {...args}
      {...restArgs}
      style={{
        backgroundColor: bgColor,
      }}
    />
  );
};
