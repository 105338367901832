import React, { useContext } from "react";
import { Input } from "antd";
import { ThemeContext } from "../../providers";

interface TextInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TextInput = (props: TextInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;
  return (
    <Input
      placeholder={component.placeholder}
      value={data[component.id]}
      onChange={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
      key={`TextInput-${component.id}`}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        border: 0
      }}
    />
  );
};
