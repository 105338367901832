import React, { useContext } from "react";
import { Select } from "antd";
import { ThemeContext } from "../../providers";

interface SelectInputProps {
  data: any;
  component: any;
  args: any;
  allowMultiSelect: boolean;
  onChange: (component: any, value: any) => void;
}

export const SelectInput = (props: SelectInputProps) => {
  const { theme } = useContext(ThemeContext);
  const {
    data,
    component,
    args,
    allowMultiSelect = false,
    onChange,
    ...rest
  } = props;
  return (
    <Select
      placeholder={component.placeholder}
      value={data[component.id]}
      mode={allowMultiSelect ? "multiple" : undefined}
      onChange={(value) => onChange(component, value)}
      {...args}
      {...rest}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        borderRadius: 5
      }}
      dropdownStyle={{ backgroundColor: theme.isDark ? "gray" : "white" }}
    >
      {component.options.map((option: any) => (
        <Select.Option
          key={option.value}
          value={option.value}
          style={{
            backgroundColor: theme.isDark ? "gray" : "white",
          }}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
