import React, { useContext } from "react";
import { InputNumber } from "antd";
import { ThemeContext } from "../../providers";

interface NumberInputProps {
  data: any;
  component: any;
  onChange: (component: any, value: any) => void;
  args?: any;
}

export const NumberInput = (props: NumberInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;
  return (
    <InputNumber
      min={component.validation?.min ?? 0}
      max={component.validation?.max}
      step={component.step ? component.step : 1}
      placeholder={component.placeholder}
      value={data[component.id]}
      onChange={(value) => onChange(component, value)}
      {...args}
      {...rest}
      className={`h-9 flex items-center ${theme.isDark ? "number-input-dark" : ""}`}
      style={{
        minWidth: "100%",
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        border: 0,
      }}
    />
  );
};
