import { Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";

import { IMessage, OS } from "@twin-shared";
import { StationaryActionType, StationaryPositionType } from "@twin-shared";
import { Collapse } from "react-collapse";

import { AntdIcon } from "../AntdIcon";
import { UiComponent } from "../../features/message/UiComponent";
import { useOnClickOutside } from "../../hooks";
import { AppContext, ThemeContext } from "../../providers";

export const Stationary = ({
  component,
  iconname,
}: {
  component: any;
  iconname: string;
}) => {
  const { theme } = useContext(ThemeContext);
  const { header, content, action, position, message_id } = component;
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setExpanded(false));
  const { os } = useContext(AppContext);

  if (action === StationaryActionType.HIDE) return <></>;
  let positionClassName = "";
  if (position === StationaryPositionType.TOP_LEFT) {
    positionClassName =
      "top-1 lg:left-HORIZONTAL_MARGIN md:left-[10%] left-[5%]";
  } else if (position === StationaryPositionType.TOP_RIGHT) {
    positionClassName =
      "top-1 lg:right-HORIZONTAL_MARGIN md:right-[10%] right-[5%]";
  } else {
    return <></>;
  }

  const headerPayload = {
    payload: header,
    id: component.id,
  } as unknown as IMessage;

  return (
    <div className={`fixed ${positionClassName} z-[1]`}>
      <div
        onClick={() => setExpanded(true)}
        className="cursor-pointer text-white p-[2px] bg-gray-500 rounded-[5px]"
        style={{
          backgroundColor:
            iconname === "ShoppingCart" ? "rgb(221, 126, 107)" : "#148898",
        }}
      >
        <AntdIcon type={iconname} className="text-[30px]" />
      </div>
      <div className="relative" ref={ref}>
        <div
          style={{ scrollbarWidth: "thin", backgroundColor: theme.colors.background  }}
          className={`w-fit absolute ${positionClassName} overflow-auto overflow-y-scroll ${os === OS.Windows ? "pr-[0px]" : "pr-[10px]"}`}
        >
          <div
            className={`${expanded && "max-h-[85vh]"}`}
            style={{ minWidth: 300, backgroundColor: theme.colors.background }}
          >
            <Typography
              component="div"
              sx={{
                whiteSpace: "pre-wrap",
                fontSize: "1rem",
                color: "black",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
                "@media (max-width: 400px)": {
                  fontSize: "0.8rem",
                },
                "@media (max-width: 300px)": {
                  fontSize: "0.7rem",
                },
                "@media (max-width: 200px)": {
                  fontSize: "0.6rem",
                },
              }}
            >
              <Collapse isOpened={expanded}>
                <div className="flex justify-between">
                  <div className="flex-grow">
                    <UiComponent
                      message_id={message_id}
                      message={headerPayload}
                    />
                  </div>
                  <div
                    onClick={() => setExpanded(false)}
                    className="cursor-pointer"
                  >
                    <AntdIcon
                      style={{ color: "black", fontSize: "15px" }}
                      type={`Up`}
                    />
                  </div>
                </div>
                {/* {render(content)} */}
                <UiComponent
                  message_id={message_id}
                  message={{ payload: content } as IMessage}
                />
              </Collapse>
              {/* {children} */}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
