import React from 'react';
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
 

interface CheckBoxInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const CheckBoxInput = (props: CheckBoxInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  
  return (
    <Checkbox
      defaultChecked={component.default_value}
      checked={data[component.id] ?? component.default_value}
      onChange={(e: CheckboxChangeEvent) => {
         onChange(component, e?.target?.checked)
      }}
      {...args}
      {...rest}
      {...component?.ui_args?.web}
     >
      {component.label}
    </Checkbox> 
  );
};
