import React, { useContext } from "react";
import { ReactComponent as HomeSvg } from "@twin-shared/lib/assets/home.svg";
import { ThemeContext } from "../../providers";

interface HotelTravelListSummaryProps {
  location: string;
  checkin_date: string;
  checkin_time: string;
  price: string;
  checkout_date: string;
  checkout_time: string;
  price_base: string;
  rating: string;
  hotel_name: string;
}

const style1 = "text-[14px] text-black";
const style2 = "text-[12px] text-gray-600";

export const HotelTravelListSummary = ({ component }: { component: any }) => {
  const args: HotelTravelListSummaryProps = component.args;
  const { theme } = useContext(ThemeContext);

  return (
    <div className="font-calibri min-w-max">
      <div
        className="flex gap-[5px] items-center"
        style={{ color: theme.colors.textPrimary }}
      >
        <HomeSvg />

        <div className="flex gap-[5px] flex-grow">
          <div className={style1} style={{ color: theme.colors.textPrimary }}>
            {args.location}
          </div>
          <div>
            <div>
              <span
                className={style1}
                style={{ color: theme.colors.textPrimary }}
              >
                {args.checkin_date}
              </span>
              <span> </span>
              <span
                className={style2}
                style={{ color: theme.colors.textSecondary }}
              >
                {args.checkin_time}
              </span>
            </div>

            <div>
              <span
                className={style1}
                style={{ color: theme.colors.textPrimary }}
              >
                {args.checkout_date}
              </span>
              <span> </span>
              <span
                className={style2}
                style={{ color: theme.colors.textSecondary }}
              >
                {args.checkout_time}
              </span>
            </div>
          </div>
        </div>

        <div className="text-right">
          <div className={style1} style={{ color: theme.colors.textPrimary }}>
            {args.price}
          </div>
          <div className={style2} style={{ color: theme.colors.textSecondary }}>
            {args.price_base}
          </div>
        </div>
      </div>

      <div className="flex gap-[5px] items-center">
        <div className="text-white bg-gray-500 px-[4px] rounded-[3px] leading-[15px]">
          {args.rating}
        </div>
        <div className="truncate text-[14px] text-gray-600 ">
          {args.hotel_name}
        </div>
      </div>
    </div>
  );
};
