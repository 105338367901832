import React, { useContext, useMemo } from "react";
import { ChatBubble } from "./ChatBubble";
import { UiComponent } from "./UiComponent";
import { Card } from "@mui/material";
import { ThemeContext } from "../../providers";

interface ChatMessageProps {
  message: any;
  style?: any;
  index?: number;
  setRef?: (ref: any) => void;
}

const InnerChatMessage = ({ message, index }: ChatMessageProps) => {
  const {theme} = useContext(ThemeContext);
  if (message.type === "text") {
    return (
      <Card
        key={`card-${message.id}-${index}`}
        className="text-lg !bg-transparent !shadow-none"
        style={{color: theme.colors.textPrimary}}
      >
        {message.payload}
      </Card>
    );
  }
  return (
    <UiComponent message={message} key={`uicomponent-${message.id}-${index}`} />
  );
};

export const Message = ({ message, index, setRef }: ChatMessageProps) => {
  const { theme } = useContext(ThemeContext);

  return useMemo(() => {
    if (!message.payload) return <></>;

    return (
      <ChatBubble
        message={message}
        key={`chatbubble-${message.id}-${index}`}
        setRef={setRef}
      >
        <InnerChatMessage message={message} index={index} />
      </ChatBubble>
    );
  }, [message, theme.name]);
};
